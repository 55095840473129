import React from "react";
import { createRoot } from "react-dom/client";
import { ApplicationForm } from "./ApplicationForm";

class ReactAdapter {
	mount(domContainer) {
		const root = createRoot(domContainer);
		root.render(this.render());
	}
	render() {
		throw new Error("Not implemented");
	}
}

class ApplicationFormContainer extends ReactAdapter {
	constructor(form) {
		super();
		this.form = form;
	}
	render() {
		return <ApplicationForm fields={this.form.fields} />;
	}
}

window.telepath.register(
	"forms.ApplicationContainer",
	ApplicationFormContainer,
);

class ApplicationFormAdapter {
	constructor(fields) {
		this.fields = fields;
	}
}

window.telepath.register("forms.ApplicationForm", ApplicationFormAdapter);
