import {
	Combobox,
	ComboboxInput,
	ComboboxOption,
	ComboboxOptions,
	Field,
	Label,
} from "@headlessui/react";
import React, { Fragment, useState } from "react";

import { useController } from "react-hook-form";

export const SearchSelect = ({
	label,
	choices,
	name,
	placeholder,
	disabled,
	className,
}) => {
	const [query, setQuery] = useState("");
	const { field } = useController({ name });
	const filteredOptions =
		query === ""
			? choices
			: choices.filter((option) => {
					return option[1].toLowerCase().includes(query.toLowerCase());
				});
	return (
		<Field>
			<Combobox
				value={field.value || ""}
				onChange={field.onChange}
				immediate
				as="div"
				className={"relative " + (className ? className : "")}
			>
				<Label
					className={
						"absolute px-1 translate-y-[-12px] z-10 ui-open:z-40 peer-focus:text-blue peer-disabled:text-grey left-8 bg-white capitalize"
					}
				>
					{label}
				</Label>
				<ComboboxInput
					className={"autocomplete-input"}
					onChange={(event) => setQuery(event.target.value)}
					displayValue={(option) => {
						return option
							? choices.find((choice) => choice[0] === option)?.[1]
							: "";
					}}
					placeholder={placeholder}
					disabled={disabled}
				/>
				<ComboboxOptions className="autocomplete">
					{filteredOptions.length > 0 ? (
						filteredOptions.map((option) => (
							<ComboboxOption key={option[0]} value={option[0]} as={Fragment}>
								{({ active, selected }) => (
									<li className="result-node">
										{option[1]}
										{selected && option[0] && <span>Selected</span>}
									</li>
								)}
							</ComboboxOption>
						))
					) : (
						<li className="result-node">No results found</li>
					)}
				</ComboboxOptions>
			</Combobox>
		</Field>
	);
};
